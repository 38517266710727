import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  IStudentTypesAOT,
  IReportResultTimeLines,
} from "../../Modals/GradeOverTime";
import * as XLSX from "xlsx";
import AreaTree from "../Treeview/AreaOverTImeTreeView";
import { tabOptions } from "../../Utilities/constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ITreeTabComponent {
  tableMap: any;
  tableData: IStudentTypesAOT;
  downloadAsExcel?: any;
  fileName?: string;
  type?: XLSX.BookType;
  tab?: any;
  tableHeaderData?: IReportResultTimeLines[];
  reportType: string;
  startDate: string;
  isBaselineUploaded:boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Tree-tabpanel-${index}`}
      aria-labelledby={`Tree-tab-${index}`}
      {...other}
      className="treeviewTableBox"
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabProps(index: number) {
  return {
    id: `Tree-tab-${index}`,
    "aria-controls": `Tree-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tableMap,
  tableData,
  tableHeaderData,
  reportType,
  startDate,
  isBaselineUploaded
}: ITreeTabComponent) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabOptions
          .filter((_,index)=> (isBaselineUploaded ? index === 2 : true))
          .map((optionName: string, index: number) => (
            <Tab label={optionName} {...TabProps(index)} />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AreaTree
          tableMap={tableMap}
          tableData={tableData.TotalEnrolled}
          tableHeaderData={tableHeaderData}
          reportType={reportType}
          startDate={startDate}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AreaTree
          tableMap={tableMap}
          tableData={tableData.PreRegStundets}
          tableHeaderData={tableHeaderData}
          reportType={reportType}
          startDate={startDate}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <AreaTree
          tableMap={tableMap}
          tableData={tableData.EnrolledStundets}
          tableHeaderData={tableHeaderData}
          reportType={reportType}
          startDate={startDate}
        />
      </CustomTabPanel>
    </Box>
  );
}
