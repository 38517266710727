import React, { useState, useEffect } from "react";
import Layout from "../../General/Layout";
import Grid from "@mui/material/Grid";
import BasicCard from "../../General/Cards/BasicCard";
import { tableMap } from "./map";
import TabWrapper from "../../General/Tabs/TabWrapperForArea";
import {
  IStudentTypesAOT,
  IReportResultTimeLines,
} from "../../Modals/GradeOverTime";
import { searchReport } from "../../Actions/generalActions";
import { useParams } from "react-router-dom";
import DownloadAsExcel from "../../General/Buttons/DownloadAsExcel";
import { downloadReport } from "../../Actions/downloadReportAction";

const Index = () => {
  const [tableData, setTableData] = React.useState<IStudentTypesAOT>();
  const [reportData, setReportData] = React.useState<any>();
  const [tableHeaderData, setHeaderTableData] =
    React.useState<IReportResultTimeLines[]>();
  const { id } = useParams();

  useEffect(() => {
    if (id) searchReport(id, setTableData, setHeaderTableData, setReportData);
  }, [id]);

  return (
    <Layout isBreadCrumHidden={true}>
      <Grid lg={12}>
        {tableData ? (
          <BasicCard heading="Area Over Time">
            <DownloadAsExcel
              primaryFunction={() =>
                downloadReport(reportData.id, "Area Over Time Report.xlsx")
              }
            />
            <TabWrapper
              tableMap={tableMap.slice(0, 3 + reportData.frequenceyNumber * 2)}
              tableData={tableData}
              downloadAsExcel="Download as Excel"
              tableHeaderData={tableHeaderData}
              reportType={reportData.frequenceyType}
              startDate={reportData.startDate}
              isBaselineUploaded = {(reportData.isBaselineUploaded == true || reportData.baselineFileId !=null ? true:false) }
            />
          </BasicCard>
        ) : (
          <></>
        )}
      </Grid>
    </Layout>
  );
};

export default Index;
